export class programviewmodel {
  public programId: string = '';
  public patientId: string = '';
  public programName: string = '';
  public programRefId: string = '';
  public programGroup: string = '';
  public programGroupId: string = '';
  public description: string = '';
  public acronym: string = '';
  public pharmaRef: string = '';
  public programWithdrawnTypeId: string = '';
  public programWithdrawDescription: string | null = '';
  public withdrawnref: string | null = '';
  public programNotes: string | null = '';
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public customendDate: string = '';
  public dateCreated: Date = new Date();
  public dateDeleted: Date = new Date();
  public dateUpdated: Date = new Date();
  public status: boolean = false;
  public isDeleted: boolean = false;
  public modificationHistory: string = '';
  public patientProgramId: string = '';
  public patientprogramstatus: string = '';
  public isTherapyCreated: boolean = false;
  public institutionPrescriberMappingId: string = '';
  public patientOrigin: string = '';
  public patientOriginCombination: string = '';
  public institutionId: string = '';
}
export class programactivityviewmodel {
  public patientProgramActivityId: string | null = null;
  public patientProgramId: string = '';
  public patientId: string = '';
  public programName: string = '';
  public activityUid: string | null = null;
  public patientProgramActivityStatusId: string = '';
  public patientProgramActivityStatusIdName: string = '';
  public dateRecorded: Date = new Date();
  public programActivityId: string = '';

  public programId: string = '';
  public programActivityName: string = '';
  public programActivitySubOptionId: string = '';
  public programActivitySubOptionName: string = '';
  public programActivityTypeId: string = '';

  public programActivityTypeName: string = '';
  public patientProgramInvoiceRef: string = '';
  public patientProgramActivityNotes: string = '';
  public UploadResponseModel = new Array<any>();
  public programActivityFiles = new Array<FileRepository>();
  public FileIds = new Array<string>();

}


export interface FileRepository {
  fileId: string;
  entityId: string;
  moduleId: string;
  fileName: string;
  filePathway: string;
  fileType: string;
  fileSize: number;
  dateCreated: string | null;
  dateUpdated: string | null;
  dateDeleted: string | null;
  status: boolean | null;
  isDeleted: boolean | null;
  modificationHistory: string;
  user: string;

}
export class programgroupviewmodel {
  public programGroupId: string = '';
  public programGroupName: string = '';
  public description: string = '';
  public acronym: string = '';
  public dateCreated: Date = new Date();
  public dateDeleted: Date = new Date();
  public dateUpdated: Date = new Date();
  public status: boolean = false;
  public isDeleted: boolean = false;
  public modificationHistory: string = '';
  public programId: string = '';
}



export class Programgroupexcellistmodel {
  public entity: Array<Programgroupexcelmodel> = new Array<Programgroupexcelmodel>();
}

export class Programgroupexcelmodel {
  profileStatus: boolean
  profileStatusValue: string
  patientId: string
  patientUID: number
  patientFirstName: string
  patientLastName: string
  patientFullName: string
  dob: string
  programName: string
  programId: string
  programRefId: string
  medicareNo: any
  progIcon: any
  progAcronym: string
  programGroup: string
  programStatus: string
  programUID: number
  pharmaUID: string
  entrollmentDate: string
  withdrownDate?: string
  profileStatusvalue: string
  customWithdrownDate: any
  patientOrigin: string
  withdrawnType: string
  withdrawnRef: string
  programNotes: string
}
