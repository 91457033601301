<!-- cstmModalBody -->
<div class="cstmModalBody">
  <!-- cstmModalCont -->
  <div class="cstmModalCont">
    <div class="SuccessBox">
        <img src="/images/successTick.svg">
        <p>{{message}}</p>
    </div>
  </div>
  <!-- cstmModalCont -->
</div> 
<!-- cstmModalBody -->  
