import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-ordersuccesspopup',
    templateUrl: './ordersuccesspopup.component.html'
  })
  export class OrdersuccesspopupComponent implements OnInit {

  public message: string = '';
    constructor(public dialogRef: MatDialogRef<OrdersuccesspopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
    {
      this.message = data.message;
    }

    ngOnInit(): void {
        
      }

    }
