import { TagMappingViewModel } from '../../patient/viewmodels/patient.viewmodel';

export class OrderViewModel {
  public orderDetailId: string = '';
  public orderDetailItemId: string = '';
  public orderReferenceId: string = '';
  public patientId: string = '';
  public patientName: string = '';
  public programs: string = '';
  public programSubGroups: string = '';
  public dateCreated: Date = new Date();
  public orderTotal: number;
  public orderStatus: string = '';
  public hexDecimalColour: string = '';
  public deliveryAddress: string = '';
  public addressType: string = '';
  public deliveryProvider: string = '';
  public completeAddress: string = '';
  public deliveryContactNo: string = '';
  public paymentStatus: string = '';
  public paymentDate: string = '';
  public therapyDeadLineDate: string = '';
  public deliveryInstructions: string = '';
  public patientAddressId: string = '';
  public trackingOrderStatus: string = '';
  public therapy: string = '';
  public therapySchedulePatientId: string = '';
  public addressDeliveryProvider: string = '';
  public deliveryLocationId: string = '';
  public hexRowColour: string = '';
  public orderServiceType: string = '';
  public apartment_Unit: string = '';
  public deliveryProviderId: string = '';
  public primaryContact: boolean;
  public dateOfBirth: string = '';
  public gender: string = '';
  public patientUid: string = '';
  public contactInfo: string = '';
  public contactName: string = '';
  public dispensingStatus: string = '';

  public dispensingError: string='';
  public tags: Array<TagMappingViewModel> = new Array<TagMappingViewModel>();
  public therapyItems: Array<TherapyItemsViewModel> =
    new Array<TherapyItemsViewModel>();
}

export class OrderExcelViewModel {
  public orderReferenceId: string = '';
  public orderStatus: string = '';
  //public patientName: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public confirmationStatus: string = '';
  public changeRequestStatus: string = '';



  public addressLine1: string = '';
  public addressLine2: string = '';
  public suburb: string = '';
  public state: string = '';

  public postcode: string = '';
  public scriptStatus: string | null = '';

  public programs: string = '';

  public deliveryContactNo: string = '';
  public proposedDeliveryDate: Date = new Date();
  public estimatedDeliveryDate: Date = new Date();
  public actualDeliveryDate: Date = new Date();
  public dispatchDate: Date = new Date();
  public programSubGroups: string = '';
  public therapyDeadLineDate: Date = new Date();
  public deliveryProvider: string = '';
  public trackingNo: string = '';

  public dateCreated: Date = new Date();
  public paymentStatus: string = '';

  public dispensingStatus: string = '';

  public dispensingError: string='';
}

export class OrderItemViewModel {
  public orderDetailItemId: string = '';
  public productVariantId: string = '';
  public qty: number;
  public price: number;
  public total: number;
  public productName: string = '';
  public packSize: number;
  public patientpk: string;
  public drugpk: string;
  public scripT_PK: string;
  public status: string = '';
  public issue: string = '';
  public itemRef: string = '';
  public productType: string = '';
  public srxnoList: Array<SRXNOViewModel> = new Array<SRXNOViewModel>();
}

export class OrderJourneyModel {
  public Draft: boolean = false;
  public DraftCompleted: boolean = false;
  public DraftSystem: boolean = false;
  public Tobedispensed = false;
  public TobedispensedCompleted: boolean = false;
  public TobePacked = false;
  public TobePackedCompleted: boolean = false;
  public Packed = false;
  public PackedCompleted: boolean = false;
  public Booked = false;
  public BookedCompleted: boolean = false;
  public PickedUp = false;
  public PickedUpCompleted: boolean = false;
  public Intransit = false;
  public IntransitCompleted: boolean = false;
  public Delivered = false;
  public DeliveredCompleted: boolean = false;
  public TherapyDueDate = false;
  public Cancellation = false;
  public Return = false;
}

export class OrderShippingDetailViewModel {
  public orderDetailId: string = '';
  public courier: string = '';
  public pickUpLocation: string = '';
  public serviceType: string = '';
  public customProposedDeliveryDate: string = '';
  public customDispatchDate;
  string = '';
  public customEstimatedDeliveryDate: string = '';
  public customActualDeliveryDate: string = '';
  public shippingCosts: string = '';
  public signatureRequired: string = '';
  public standardBoxType: string = '';
  public standardBoxDimensions: string = '';
  public standardQuantity: string = '';
  public trackingNo: string = '';
  public proposedDeliveryDate: Date = new Date();
  public dispatchDate: Date = new Date();
  public estimatedDeliveryDate: Date = new Date();
  public actualDeliveryDate: Date = new Date();
  public packageDetail: Array<OrderPackageDetail> =
    new Array<OrderPackageDetail>();
}
export class OrderPackageDetail {
  public orderPackageDeliveryDetailId: string = '';
  public stdBoxType: string = '';
  public stdBoxDimensions: string = '';
  public stdQuantity: string = '';
  public stdWeight: string = '';
}

export class OrderJourneyStatusModel {
  public customDateUpdated: string = '';
  public user: string = '';
}

export class GetOrderJourneyStatusModel {
  public orderDetailId: string = '';
  public dateUpdated: string = '';
  public customDateUpdated: string = '';
  public user: string = '';
  public status: string = '';
  public statusCode: string = '';
}

export class OrderJourneyStepsModel {
  public DraftUser: string = '';
  public DraftDate: string = '';
  public ToBeDispensedUser: string = '';
  public ToBeDispensedDate: string = '';
  public ToBePackedUser: string = '';
  public ToBePackedDate: string = '';
  public PackedUser: string = '';
  public PackedDate: string = '';
  public BookedUser: string = '';
  public BookedDate: string = '';
  public PickedUpUser: string = '';
  public PickedUpDate: string = '';
  public PickedUpDPDate: string = '';
  public InTransitUser: string = '';
  public InTransitDate: string = '';
  public InTransitDPDate: string = '';
  public DeliveredUser: string = '';
  public DeliveredDate: string = '';
  public DeliveredDPDate: string = '';
  public TherapyDueDateUser: string = '';
  public TherapyDueDateDate: string = '';
}

export class OrderTaskJournetStepsModel {
  public ConfirmRecommendedDeliveryDateTask: boolean = false;
  public ConfirmRecommendedDeliveryDateTaskDate: string = '';
  public ConfirmRecommendedDeliveryDateTaskUser: string = '';
  public ApproveChangeRequest: boolean = false;
  public ApproveChangeRequestDate: string = '';
  public ApproveChangeRequestUser: string = '';
  public OrderTaskCompleted: boolean = false;
  public OrderTaskRejected: boolean = false;
  public ShowRequestForChangeActive = true;
}

export class PatientAddressModel {
  public addressId: string = '';
  public completeAddress: string = '';
  public primaryAddress: boolean = false;
  public deliveryLocationId: string = '';
}

export class CurrentAssignmentViewModel {
  public roleId: string = '';
  public roleName: string = '';
}

export class PaymentDetailViewModel {
  public receiptNo: string = '';
  public amount: string = '';
  public paymentDate: string = '';
  public paymentMethod: string = '';
}
export class SRXNOViewModel {
  public SRXNO: string = '';
}
export class OrderServiceTypeModel {
  public orderServiceTypeId: string = '';
  public serviceType: string = '';
  public serviceTypeCode: string = '';
}

export class TherapyItemsViewModel {
  public therapyName: string = '';
  public orderDueDate?: Date = new Date();
  public scheduledNextDueDate?: Date = new Date();
  public therapySchedulePatientId: string = '';
  public patientProgramPharmaRefNo: string = '';
  public therapyStartDate?: Date = new Date();
  public therpayEndDate?: Date = new Date();
  public programStartDate: Date = new Date();
  public programEndDate: Date = new Date();
  public orderLimit: string = '';
  public rXonHand: string = '';
  public prescribeLinkedCount: number = 0;
  public prescribeItems: Array<PrescribeItemsDetailViewModel> =
    new Array<PrescribeItemsDetailViewModel>();
  public orderItems: Array<OrderItemViewModel> =
    new Array<OrderItemViewModel>();
  public isVisible = false;
}

export class PrescribeItemsDetailViewModel {
  public prescriberTitle: string = '';
  public prescriberFirstName: string = '';
  public prescriberLastName: string = '';
  public linkStartDate: Date = new Date();
  public linkEndDate: Date = new Date();
  public institutionCode: string = '';
  public state: string = '';
  public institutionName: string = '';
  public showContainer: boolean = false;
}
